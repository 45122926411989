import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import style from './ScrollHijackGroup.module.scss';
import { useDispatch } from 'react-redux';
import { openPlayerModal, updatePlayerLink, updatePlayerPoster } from '../../redux/modal/playerModal.action';

import Player from '../Player';
import Image from 'next/image';
import LottieAnimation from '../LottieAnimation';
import CountingNumbers from '../CountingNumbers';
import QuoteCarousel from '../QuoteCarousel';

// const OverlayImage = () => (
//     <svg xmlns='http://www.w3.org/2000/svg' width='592' height='316' fill='none' viewBox='0 0 592 316'>
//         <path
//             fill='#E67C25'
//             d='M156.358 264.462c34.371-57.137-51.19-109.584-87.645-53.737L0 316h122.75c15.557-23.776 27.591-42.157 31.319-47.932.821-1.202 1.555-2.404 2.289-3.606zM473.946 76.75c33.021-57.401-52.276-108.353-88.261-52.77L196.717 316H319.79C387.24 211.751 462.734 94.955 471.657 80.561c.792-1.26 1.555-2.55 2.289-3.811zM591.999 11.316L394.637 316h122.28c23.452-36.176 49.047-75.665 75.082-115.799V11.316z'
//         />
//     </svg>
// );

const ScrollHijackGroup = ({ data, isMobile }) => {
    const { title, description, image, video, coverImage } = data[0];
    if (data.length <= 1) {
        data.push({});
    }

    const [currentUrl, getCurrentUrl] = useState('');
    const { statements, title: title2, stats, quotes } = data[1];

    const dispatch = useDispatch();

    const openPlayer = (link, poster) => {
        dispatch(openPlayerModal());
        dispatch(updatePlayerLink(link));
        dispatch(updatePlayerPoster(poster));
    };

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const videoOnPlay = () => {
        setIsVideoPlaying(true);
    };

    useEffect(() => {
        getCurrentUrl(window.location.href);
    });

    let newTitle = title;
    if (title.toLowerCase().indexOf('private equity') > -1 && title.match(/^(?!.*<[^>]+>).*/)) {
        const titleArr = title.split(' ');
        if (titleArr.length > 2) {
            const tmpArr = titleArr.map((t, i) => {
                return i === 2 && i === titleArr - 1
                    ? `<span>${t}</span>`
                    : i === 2
                    ? `<span>${t} `
                    : i === titleArr - 1
                    ? `${t}</span>`
                    : `${t} `;
            });
            newTitle = tmpArr.join('');
        }
    }

    return (
        <div className={style.el}>
            <div className={style.innerHeader}>
                <div className={style.content}>
                    <div className={[style.container, 'container'].join(' ')}>
                        {newTitle && (
                            <>
                                <div className={style.contentWrapper}>
                                    <h1 className={style.title} dangerouslySetInnerHTML={{ __html: newTitle }} />
                                    {description && (
                                        <>
                                            <p
                                                className={style.description}
                                                dangerouslySetInnerHTML={{
                                                    __html: description,
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        {quotes && (
                            <>
                                {
                                    <div className={style.contentWrapper}>
                                        <QuoteCarousel
                                            quotes={quotes}
                                            currentUrl={currentUrl}
                                        />
                                    </div>
                                }
                            </>
                            )
                        }
                    </div>
                </div>
                <div className={style.scrollGroup}>
                    <div className={style.image}>
                        <Image src={video && isMobile ? '' : image.url} alt="" layout="fill" />
                        {video && (
                            <>
                                {isMobile ? (
                                    <div key="mobile-video" className={style.videoWrapper}>
                                        <Player
                                            link={video}
                                            poster={coverImage?.url}
                                            isAutoPlay={false}
                                            onPlay={videoOnPlay}
                                        />
                                        {!isVideoPlaying && (
                                            <div
                                                className={style.videoPoster}
                                                style={{
                                                    backgroundImage: `url(${image.url})`,
                                                }}
                                            >
                                                <button type="button" className={style.playButton}>
                                                    <img src="/images/people/play.png" alt="play" />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <button
                                        key="desktop-video"
                                        type="button"
                                        className={style.playButton}
                                        onClick={() => openPlayer(video, coverImage?.url)}
                                    >
                                        <img src="/images/people/play.png" alt="play" />
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                    {statements && (
                        <div className={style.fourColsInfo}>
                            <div className={[style.container, 'container'].join(' ')}>
                                <div className={style.contentStats}>
                                    {title2 && <h2 className={style.titleStats}>{title2}</h2>}
                                    <div className={style.itemsStats}>
                                        {statements &&
                                            statements.map((item, i) => {
                                                return (
                                                    <div className={style.itemStats} key={i}>
                                                        {item.animationJson ? (
                                                            <div className={style.iconImageStats}>
                                                                <LottieAnimation
                                                                    json={item.animationJson}
                                                                    playImmediately={false}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <img
                                                                className={style.iconImageStats}
                                                                src={item.image.url}
                                                                alt={item.image.alt}
                                                            />
                                                        )}
                                                        <div
                                                            className={style.descStats}
                                                            dangerouslySetInnerHTML={{
                                                                __html: item.text,
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </div>
                                    <div
                                        className={style.stats}
                                        style={
                                            statements && statements.length && stats.length >= 1
                                                ? {
                                                      borderTop: '1px solid #C9C9D7',
                                                  }
                                                : {}
                                        }
                                    >
                                        {stats &&
                                            stats.map((stats, id) => (
                                                <div className={style.statsInfo} key={id}>
                                                    <div className={['h1', style.statsTitle].join(' ')}>
                                                        {stats.disableCountingAnimation ? (
                                                            <>{stats.title}</>
                                                        ) : (
                                                            <CountingNumbers value={stats.title} />
                                                        )}
                                                    </div>
                                                    <div
                                                        className={style.statsDesc}
                                                        dangerouslySetInnerHTML={{
                                                            __html: stats.description,
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

ScrollHijackGroup.propTypes = {
    data: PropTypes.array,
    isMobile: PropTypes.bool,
};
export default ScrollHijackGroup;
